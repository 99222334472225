import React from "react"

const Quotes = () => {
  return (
    <svg viewBox="0 0 78 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 4C0.5 3.72386 0.723858 3.5 1 3.5H26.5C26.7761 3.5 27 3.72386 27 4C27 4.27614 26.7761 4.5 26.5 4.5H1C0.723858 4.5 0.5 4.27614 0.5 4Z"
        fill="#CBD5E0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.5 4C51.5 3.72386 51.7239 3.5 52 3.5H77.5C77.7761 3.5 78 3.72386 78 4C78 4.27614 77.7761 4.5 77.5 4.5H52C51.7239 4.5 51.5 4.27614 51.5 4Z"
        fill="#CBD5E0"
      />
      <path
        d="M43 4.05085C43 4.32203 42.9504 4.57627 42.8513 4.81356C42.7621 5.05085 42.6382 5.25989 42.4796 5.44068C42.3309 5.61017 42.1524 5.74576 41.9442 5.84746C41.7361 5.94915 41.513 6 41.2751 6C41.0372 6 40.8141 5.94915 40.6059 5.84746C40.3978 5.74576 40.2144 5.61017 40.0558 5.44068C39.9071 5.25989 39.7881 5.05085 39.6989 4.81356C39.6097 4.57627 39.5651 4.32203 39.5651 4.05085C39.5651 3.59887 39.6295 3.18079 39.7584 2.79661C39.8872 2.40113 40.0607 2.0339 40.2788 1.69492C40.5068 1.35593 40.7646 1.0452 41.052 0.762712C41.3494 0.480226 41.6568 0.225989 41.974 0L42.5242 0.542373C42.3358 0.734463 42.1623 0.977401 42.0037 1.27119C41.8451 1.56497 41.741 1.85876 41.6914 2.15254C42.0682 2.25424 42.3804 2.48588 42.6283 2.84746C42.8761 3.19774 43 3.59887 43 4.05085ZM38.4349 4.05085C38.4349 4.32203 38.3854 4.57627 38.2862 4.81356C38.197 5.05085 38.0731 5.25989 37.9145 5.44068C37.7658 5.61017 37.5824 5.74576 37.3643 5.84746C37.1561 5.94915 36.9331 6 36.6952 6C36.4572 6 36.2342 5.94915 36.026 5.84746C35.8278 5.74576 35.6493 5.61017 35.4907 5.44068C35.342 5.25989 35.223 5.05085 35.1338 4.81356C35.0446 4.57627 35 4.32203 35 4.05085C35 3.59887 35.0644 3.18079 35.1933 2.79661C35.3222 2.40113 35.4957 2.0339 35.7138 1.69492C35.9418 1.35593 36.1995 1.0452 36.487 0.762712C36.7844 0.480226 37.0917 0.225989 37.4089 0L37.9442 0.542373C37.7658 0.734463 37.5973 0.977401 37.4387 1.27119C37.2801 1.56497 37.176 1.85876 37.1264 2.15254C37.5031 2.25424 37.8154 2.48588 38.0632 2.84746C38.311 3.19774 38.4349 3.59887 38.4349 4.05085Z"
        fill="#CBD5E0"
      />
    </svg>
  )
}

export default Quotes
