import React from "react"
import Wave from "../wave"
import SectionTitle from "../sectionTitle"
import Separator from "../separator"
import "./plan.css"

let Star = () => (
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 0L8.5716 4.83688H13.6574L9.5429 7.82624L11.1145 12.6631L7 9.67376L2.8855 12.6631L4.4571 7.82624L0.342604 4.83688H5.4284L7 0Z"
      fill="#4C51BF"
    />
  </svg>
)

const Plan = ({ planSection }) => {
  const { title, subtitle, price, repeater } = planSection
  let splitPrice = price.split(".")
  return (
    <section className="bg-gray-100 bg">
      <Wave className="text-white" invertX />
      <article className="px-5 w-full md:w-9/12 lg:w-7/12 xl:w-5/12 mx-auto pt-24 pb-20 flex flex-col md:flex-row items-center justify-between">
        <div className="w-full sm:w-10/12 md:flex-1">
          <SectionTitle>{title}</SectionTitle>
          <p className="mb-10">{subtitle}</p>
          <div className="font-bold text-lg">
            {repeater.map(({ feature }, index) => (
              <p key={index} className="flex items-center mb-4">
                <Star /> <span className="ml-2">{feature}</span>
              </p>
            ))}
            <a href="#" className="link mt-6">
              Start free now!
            </a>
          </div>
        </div>
        <div className="mt-5 md:mt-0 w-full md:w-auto md:flex-shrink-0 md:pl-16 pt-8 md:pt-0 sm:w-10/12">
          <p
            className="text-indigo-700 font-bold flex items-start leading-tight"
            style={{ fontSize: "100px" }}
          >
            ${splitPrice[0]}
            <span className="text-lg mt-5">.{splitPrice[1]}</span>
          </p>
          <p>Per Month</p>
        </div>
      </article>
      <Wave className="text-white" invertY />
    </section>
  )
}

export default Plan
