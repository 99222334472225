import React from "react"
import SectionTitle from "../sectionTitle"
import Separator from "../separator"
import RenderIf from "../renderIf"

let Block = ({ question = "", answer = "" }) => {
  const [open, setOpen] = React.useState(false)
  let handleClick = () => setOpen(!open)
  return (
    <button
      className={`bg-gray-100 rounded ${
        open ? "shadow-lg" : "shadow-xs"
      } p-5 mb-5 text-left text-lg focus:outline-none hover:shadow-lg transition duration-500 ease-in-out`}
      onClick={handleClick}
    >
      <span className="flex w-full items-center justify-between">
        {question}
        <span className="text-2xl text-indigo-700">
          {open ? <>&times;</> : "+"}
        </span>
      </span>
      <RenderIf isTrue={open}>
        <span className="pt-5 mt-5 w-full block border-t">
          <span>{answer}</span>
        </span>
      </RenderIf>
    </button>
  )
}

const Faqs = ({ faqsSection, callToAction }) => {
  const { title, subtitle, repeater } = faqsSection
  const { url, label } = callToAction
  return (
    <section className="bg-white">
      <article className="container px-5 mx-auto text-center pt-10 pb-20">
        <SectionTitle>{title}</SectionTitle>
        <p className="mb-10">{subtitle}</p>

        <div className="flex flex-col mx-auto w-full sm:w-10/12 lgw-6/12 mb-8">
          {repeater.map(({ question, answer }, index) => (
            <Block key={index} question={question} answer={answer} />
          ))}
        </div>
        <a href={url} className="link">
          {label}
        </a>
      </article>
    </section>
  )
}

export default Faqs
