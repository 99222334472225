import React from "react"
import Arrow from "./arrow"
import Wave from "../wave"
import Separator from "../separator"
import SectionTitle from "../sectionTitle"

let Block = ({ title = "", children, number = "", index }) => {
  return (
    <div
      className={`flex-1 mb-4 ${index === 1 ? "md:px-5 lg:px-0" : ""} lg:mb-0`}
    >
      <p className="text-6xl font-bold text-indigo-300">{number}</p>
      <p className="text-2xl font-bold mb-3">{title}</p>
      <p className="text-indigo-200">{children}</p>
    </div>
  )
}

const HowItWorks = ({ howItWorksSection }) => {
  const { title, subtitle, repeater } = howItWorksSection
  return (
    <section
      className="bg-indigo-700"
      style={{
        backgroundColor: "#434190",
        backgroundImage: "linear-gradient(180deg, #434190 25%, #4c51bf 100%)",
      }}
    >
      <Wave className="text-white" />
      <article className="px-5 container mx-auto text-center text-indigo-100 pt-12 pb-20">
        <SectionTitle>{title}</SectionTitle>
        <p className="mb-5">{subtitle}</p>
        <div className="flex flex-col md:flex-row">
          {repeater.map(({ number, title, content }, index) => (
            <React.Fragment key={index}>
              <Block number={number} title={title} index={index}>
                {content}
              </Block>
              {number === ".01" && (
                <div className="hidden lg:block w-40 mt-24 px-5">
                  <Arrow />
                </div>
              )}
              {number === ".02" && (
                <div className="hidden lg:block w-40 mt-24 px-5">
                  <Arrow invert />
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
        <a
          href="#"
          className="bg-indigo-300 font-bold text-indigo-700 shadow-xl rounded px-8 mt-10"
          style={{
            display: "inline-table",
            paddingTop: "12px",
            paddingBottom: "12px",
          }}
        >
          Start free now!
        </a>
      </article>
    </section>
  )
}

export default HowItWorks
