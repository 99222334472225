import React from "react"
import Wave from "../wave"
import SectionTitle from "../sectionTitle"
import Separator from "../separator"
import Stars from "./stars"
import Quotes from "./quotes"

let Block = ({ storeName = "", children, spaced = false }) => {
  return (
    <div className={`flex-1 mb-10 md:mb-0 ${spaced ? "px-0 md:px-5 " : ""}`}>
      <div className="text-indigo-700 w-4/12 mx-auto mb-4">
        <Stars></Stars>
      </div>
      <p className="text-xl mb-3">{storeName}</p>
      <div className="w-4/12 mx-auto mb-4">
        <Quotes></Quotes>
      </div>
      <p>{children}</p>
    </div>
  )
}

const Testimonials = ({ testimonialsSection }) => {
  const { title, subtitle, repeater } = testimonialsSection
  return (
    <section>
      <Wave className="text-indigo-700" invertX />
      <article className="container mx-auto text-center  pb-10 md:pb-20 pt-24 px-5">
        <SectionTitle>{title}</SectionTitle>
        <p className="mb-10">{subtitle}</p>

        <div className="flex flex-col md:flex-row">
          {repeater.map(({ storeName, storeReview }, index) => (
            <Block key={storeName} storeName={storeName} spaced={index === 1}>
              {storeReview}
            </Block>
          ))}
        </div>
        <div className="text-center mt-10">
          <a href="#" className="link">
            Start free now!
          </a>
        </div>
      </article>
    </section>
  )
}

export default Testimonials
