import React from "react"

const Stars = () => {
  return (
    <svg
      viewBox="0 0 107 19"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 0L11.6329 6.90983H18.535L12.9511 11.1803L15.084 18.0902L9.5 13.8197L3.91604 18.0902L6.04893 11.1803L0.464966 6.90983H7.36712L9.5 0Z"
        fill="currentColor"
      />
      <path
        d="M31.5 0L33.6329 6.90983H40.535L34.9511 11.1803L37.084 18.0902L31.5 13.8197L25.916 18.0902L28.0489 11.1803L22.465 6.90983H29.3671L31.5 0Z"
        fill="currentColor"
      />
      <path
        d="M53.5 0L55.6329 6.90983H62.535L56.9511 11.1803L59.084 18.0902L53.5 13.8197L47.916 18.0902L50.0489 11.1803L44.465 6.90983H51.3671L53.5 0Z"
        fill="currentColor"
      />
      <path
        d="M75.5 0L77.6329 6.90983H84.535L78.9511 11.1803L81.084 18.0902L75.5 13.8197L69.916 18.0902L72.0489 11.1803L66.465 6.90983H73.3671L75.5 0Z"
        fill="currentColor"
      />
      <path
        d="M97.5 0L99.6329 6.90983H106.535L100.951 11.1803L103.084 18.0902L97.5 13.8197L91.916 18.0902L94.0489 11.1803L88.465 6.90983H95.3671L97.5 0Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Stars
