import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import {
  Intro,
  HowItWorks,
  Testimonials,
  Plan,
  Faqs,
} from "src/components/home"

const IndexPage = ({ data }) => {
  let {
    heroSection,
    howItWorksSection,
    testimonialsSection,
    planSection,
    faqsSection,
  } = data.takeshape.getHomePage
  return (
    <Layout>
      <SEO title="Home" />
      <Intro heroSection={heroSection} />
      <HowItWorks howItWorksSection={howItWorksSection} />
      <Testimonials testimonialsSection={testimonialsSection} />
      <Plan planSection={planSection} />
      <Faqs faqsSection={faqsSection} callToAction={heroSection.callToAction} />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  {
    takeshape {
      getHomePage {
        heroSection {
          content
          title
          callToAction {
            label
            url
          }
        }
        howItWorksSection {
          subtitle
          title
          repeater {
            content
            number
            title
          }
        }
        testimonialsSection {
          title
          subtitle
          repeater {
            storeName
            storeReview
          }
        }
        planSection {
          title
          subtitle
          price
          repeater {
            feature
          }
        }
        faqsSection {
          subtitle
          title
          repeater {
            answer
            question
          }
        }
      }
    }
  }
`
