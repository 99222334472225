import React from "react"
import HomeIllustration from "./homeIllustration"

const Intro = ({ heroSection }) => {
  const {
    title,
    content,
    callToAction: { url, label },
  } = heroSection
  return (
    <article className="container mx-auto mb-10 mt-12 sm:mt-24 px-5 z-90">
      <div className="w-full lg:w-6/12">
        <h1 className="text-6xl font-black leading-none mb-4">{title}</h1>
        <p className="text-xl leading-relaxed mb-4">{content}</p>
        <a href={url} className="link">
          {label}
        </a>
      </div>
      <div className="flex justify-end mt-0 md:-mt-10 lg:-mt-64">
        <div className="w-full lg:w-10/12 ">
          <HomeIllustration />
        </div>
      </div>
    </article>
  )
}

export default Intro
